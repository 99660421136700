#root {
    position: relative;
    min-height: 100%;
    overflow: hidden;
    background-color: #fff;
}
/* Introduction Video Section 👇 */
.green-bg{
    /* background-color: #E0EFE9; */
    background: linear-gradient(0deg, rgba(243,245,244,1) 0%, rgba(224,239,233,1) 14%);
    display: flex;
    flex-direction: column-reverse;
}

.intro-container {    
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: auto;
    /* background-image: url(../../../public/assets/images/background.svg); */
    /* background-repeat: no-repeat;
    background-size:cover; */
    padding: 30px 0 30px 0;
    background-color: #fff; 
    border-radius: 200px 0 200px 0;
}

.intro-section { 
    width: 90%;
    margin: 0 auto;    
}

.action-text-intro{
    display: inline-block;
    width: 50%;
    float: left;
    margin-top: 70px;
}

.action-text-intro > div {
    width: 60%;
    margin: 5px auto;
    line-height: 30px;
}
.intro-title{
    color: #676767;
    text-align: center;
    font-weight: normal;
    font-size: 35px;
    margin-bottom: 50px;
  }

  .intro-landing-h1{
    color: #676767;
    font-size: 40px  !important;
    text-align: left;
    font-weight: normal;
    margin-top: 0 !important;
  }

  .intro-paragraph{
    width: 100%;
    font-size: 25px !important;
    margin: 0 auto;
    padding: 30px 0px 0px 0px;
    color: #676767;
    text-align: left;
  }
  .video-intro-section {
    display: inline-block;
    width: 50%;
    /* float: right; */
    max-width: 600px;
    margin-top: 50px;
}
.landing-video {
    display: block;
    width: 600px;
    border-radius: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 15px auto;
}

.video-intro-section > iframe {
    /* border-radius: 45px !important; */
    overflow: hidden;
    width: 100%;
}
.mp-component_azure-mp-container__idgeq {
    /* margin: 15px auto !important; */
}
.video-intro-section > .azuremediaplayer {
   width: 100% !important;
   background-color: transparent !important;
}
.azuremediaplayer {
    border-radius: 20px !important;
}
.amp-default-skin .vjs-has-started .vjs-control-bar {
    border-radius: 0 0 20px 20px !important;
}
.amp-default-skin .vjs-controls-enabled .vjs-big-play-button {
    background-color: rgba(255, 255, 255, 0.28) !important;
    /* opacity: 20% !important; */
    height: 70px !important;
    width: 70px !important;
    visibility: visible;
    left: 50% !important;
    border-radius: 50px !important;
    top: 50% !important;
    border: none !important;
}
.amp-default-skin .vjs-big-play-button::before{
    text-shadow: none !important;
    font-size: calc(.075em) !important;
}
.amp-default-skin .vjs-poster .vjs-poster-span {
    height: 0 !important;
}
video {
    width: 100%;
}
.action-text-intro > div > .call-to-action-btn {
    margin: 25px 0px;
}

.call-to-action-btn {
    display: block;
    padding: 10px 35px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    font-size: 25px;
    background-size: 200% auto;
    color: white;
    border-radius: 50px;
    border: 0px;
    font-weight: normal;
    background-color: #F39422;
    /* box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #ffa034 0%, #ff8800  51%, #f08f10  100%); */
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.call-to-action-btn:hover , .login-item{
    background-position: right center;
    color: #fff;
    text-decoration: none;
}

.call-to-action-btn:active {
    transform: scale(0.95);
}
  
/* Read more + Read less in the introduction */
.intro-paragraph, details {
    display: inline-block;
    color: #676767;
    text-align: justify;
    width: 100%;
    line-height: 30px;
    font-size: 25px !important;
    margin: 0 auto;
}
  
  details {
    position:relative;
  }

  details summary {
    display:block;
    cursor: pointer;
    color: orange;
    text-decoration: underline;
  }

  details summary:focus {
    outline:none;
  }

  details[open] {
    display:block;
    padding-bottom:25px;
    /* padding-top:10px; */
    animation: open .2s linear;
  }

  details[open] summary {
    position:absolute;
    bottom: 0;
    left:0;
  }
  
  details #open{padding-left:5px;text-align:middle;}
  details[open] #open{display:none;}
  details #close{display:none;}
  details[open] #close{display:block;}
  
  ::-webkit-details-marker {display: none;}
  
  @keyframes open {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
@media(max-width:1290px){
    .inner-about-section-one {
        width: 90%;
    }
    .about-image-one > img {
        max-width: 500px;
    }
}

/* Categories with their courses Section 👇 */

.courses-section {
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
}

.categories-container{
    /* background-color:#fff;
    border-radius:200px 0 200px  0; */
    padding-top: 50px;
    padding-bottom: 90px;
}
/* #circle-big {
    width: 200px;
    height: 200px;
    background: #7dc1ad;
    border-radius: 50%;
    float:right;
    opacity: 90%;
  } */

/* #circle-small{
    background-color: #f29221;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 90%;
} */
.each-cat {
    display: inline-block;
    cursor: pointer;
    width: 20%;
}

.each-cat > img {
    margin: 0 auto;
    display: block;
    width: 180px;
    height: 180px;
    max-width: 100%;
}

.each-cat > p {
    text-align: center !important;
    color: #606060 !important;
    font-size: 25px !important;
}

.each-course {
    text-align: center !important;
    color: #606060 !important;
    font-size: 25px !important;
    margin: 0 auto;
    display: block;
    text-decoration: none !important;
}

.single-category{
    width: 95%;
    display: none;
    max-height: 1000px;
    min-height: 500px;
    transition: visibility 0s linear 0.33s, opacity 0.33s linear;
    /* padding-bottom: 5rem; */
    margin:-90px auto 5% auto;
}

.single-category > img {
    margin: 0 auto;
    display: block;
    width: 244px;
}

.close-cat{
    text-align: right;
    width: 100%;
    font-size: 35px;
    color: #676767;
}

.all-related-courses{
    width: 100%;
    display: flex;
    justify-content: center;
   
}

.all-related-courses > div {
    width: 95%;
    display: inline-block;
}

.all-related-courses > div > a > img{
    margin: 0 auto;
    display: block;
    padding-bottom: 13px;
    width: 43%;
}
/* Courses Slider Arrows */
.all-related-courses > .swiper .swiper-wrapper {
    justify-content: center;
}
.all-related-courses > .swiper .swiper-button-prev , 
.all-related-courses > .swiper > .swiper-button-next {
    width: 60px !important;
    height: 100% !important;
}
.prevCourse, .nextCourse
{
    display: none !important;
}
.prevCourse
{
   
    border-radius: 100% 0% 0% 100% / 50% 0% 0% 50% !important;
    top: -0% !important;
}
.nextCourse
{
    border-radius: 0% 100% 100% 0% / 0% 50% 50% 0% !important;
    top: 0 !important;
}

.all-related-courses > .slider-items > .owl-stage-outer > .owl-stage{
    justify-content: center;
    width: 100% !important;
    margin: 0 auto;
    display: flex;
}

.all-related-courses > .slider-items > .owl-stage-outer > .owl-stage > .owl-item{
    display: flex;
    justify-content:space-around;
}
.all-related-courses> .slider-items > .owl-nav > .owl-next {
    border-radius: 0% 100% 100% 0% / 0% 50% 50% 0% !important;
    background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    /* left: 92.5% !important; */
    width: 15% !important;
    height: 100% !important;
    /* top: -10px !important; */
    opacity: 45% !important;
    transition:  .2s ease-in;
}
.all-related-courses> .slider-items > .owl-nav > .owl-prev{
    border-radius: 100% 0% 0% 100% / 50% 0% 0% 50% !important;
    background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    /* right: -90% !important; */
    width: 15% !important;
    height: 100% !important;
    /* top: -10px !important;     */
    opacity: 45% !important;
    transition:  .2s ease-in;
}
.videos-carousel-container .slider-items .swiper-button-next {
    background-image: linear-gradient(to right, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    width: 160px !important;
    top: -5px !important;
    opacity: 45% !important;
    transition: .2s ease-in;
    right: 0 !important;
    height: 100% !important;
}
.videos-carousel-container .slider-items .swiper-button-next:after, .videos-carousel-container .slider-items .swiper-button-prev:after {
    content: '';
}
.videos-carousel-container .slider-items .swiper-button-prev {
    left: 0 !important;
    top: -5px !important;
    border-radius: 0;
    background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    width: 15% !important;
    height: 100% !important;
    opacity: 45% !important;
}
.videos-carousel-container .slider-items .swiper-button-next, 
.videos-carousel-container .slider-items .swiper-button-prev {
    position: absolute;
    top: 0 !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
}
.each-course > img{
    display: block;
    width: 90px !important;
    height: 90px;
    margin: 0 auto;
}
.each-course > p {
    padding: 10px 0;
}
.cat-image{
    margin: 0 auto;
    display: block;
    width: 180px;
    max-width: 100%;
}

.cat-name {
    text-align: center;
    margin-bottom: 40px;
    color: #606060 !important;
    font-size: 25px;
}

/* Content about talents Section 👇 */

.videos-carousel-container{
    display: block;
    height: 100%;
    width: 100%;
    margin-top: 0%;
    padding: 50px 0;
    background-color: #F3F5F4;
}

.swiper{
    padding-bottom: 10px !important;
}

.swiper-item{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    overflow:hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.thumbnail-img{
    width: 100%;
    height: 100%;
}

.swiper-button-disabled{
    display : none !important;
}

.owl-carousel .item {
    height: auto;
    width: 100%;
    border-radius: 25px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.owl-stage > .owl-item {
    padding: 0 10px;
}

.item-title-slider {
    opacity: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0px;
    border-radius: 0 0 22px 22px;
    background-color: #fff;
    height: auto;
    cursor: pointer;
    z-index: 5;
    margin: 0;
    text-align: center;
    font-size: 23px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    transition: .5s ease;
}
.item-title-slider > span {
    width: 90%;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 10px 0;
    font-size: 23px;
    /* white-space: nowrap; */
    /* line-height: 40px; */
    /* alignment-baseline: middle; */
    /* vertical-align: middle; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    justify-content: center;
}

.thumbnail-container {
    cursor: pointer;
    width: 100%;
}

.thumbnail-container:hover + .item-title-slider {
    opacity: 1;
}

 .videos-carousel-container > .slider-items > .owl-nav > .owl-prev {
    border-radius: 0;
    background-image: linear-gradient(to left, rgba(255,0,0,0), #fd8b02) !important;
    background-color: transparent !important;
    width: 160px;
    height: 100% !important;
    opacity: 45% !important;
}

/* the below is for display the next or prev when cards loop is end  */
.owl-nav .disabled { 
	display: none; 
}

.owl-carousel .owl-prev.disabled,.owl-carousel .owl-next.disabled{
    pointer-events: none;
    opacity: 0.2;
  }

  .playIcon {
    width: 90px !important;
    max-width: 30%;
    display: block !important;
    margin: 0 auto;
    position: absolute;
    top: 0 !important;
    top: calc(50% - 50px);
    left: 36%;
    cursor: pointer;
}

.playIcon2 {
    width: 90px !important;
    max-width: 40%;
    display: block !important;
    position: absolute;
    cursor: pointer;
    top: 35% !important;
    left: 30%;
    /* background-color: aqua; */
}
  /* .owl-carousel .prev-slide{
      background: url(nav-icon.png) no-repeat scroll 0 0;
      left: -33px;
  } */

  /* .owl-carousel .next-slide{
      background: url(nav-icon.png) no-repeat scroll -24px 0px;
      right: -33px;
  } */

  .owl-carousel .prev-slide:hover{
     background-position: 0px -53px;
  }

  .owl-carousel .next-slide:hover{
    background-position: -24px -53px;
  }

.owl-dots{
    display: none !important;
}

  /* Certificates Section 👇 */ 

  .certificate-container {
    background: url(../../../public/assets/images/solid_background_topfooter.svg) center bottom no-repeat;
    width: 100%;
    display: inline-block;
    position: relative;
    background-size: contain;
    margin: 0 auto;
    /* background-size: 100%; */
}
.certificate-container:after {
    padding-top: 15rem;
    display: block;
    content: '';
  }
  
.certificates-section{
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 7% auto;
    top: 20%;
    position: relative;
}

.single-certificate {
    display: inline-block;
    width: 20%;
}

.single-certificate > img {
    width: auto;
    margin: 0 auto;
    display: block;
}

.single-certificate > p {
    text-align: center;
    color: #606060;
    font-size: 25px;
    line-height: 50px;
}

.intro-title-certificate {
    color: #676767;
    display: block;
    /* margin: 0 auto -50px; */
    width: 100%;
    /* padding-top: 100px; */
    font-size: 33px;
    position: relative;
    top: 25%;
    text-align: center;
    font-weight: normal;
}
  /* Styles of the new LP design */

  .lp-intro-container {
    background: url(../images/lp-intro-img.webp);
    background-size: cover;
    /* height: 708px; */
    height: auto;
    /* max-height: 100%; */
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    aspect-ratio: auto 121/59;
}
  .lp-intro-title{
    color: #4D2B85;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 5rem;
  }
  .lp-intro-p {
    color: #6A6A6A;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;
    width: 90%;
    max-width: 800px;    
    margin: 0 auto;
    line-height: 25px;
}
.lp-btn {
    border-radius: 40px;
    background: #F39422;
    width: 254px;
    height: 50px;
    margin: 0 auto;
    display: block;
    color: #fff !important;
    margin-top: 3rem;
    font-size: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.web-intro{
    display: block;
}
.mob-intro{
    display: none;
}
.lp-separator{
    display: block;
    width: 85%;
    margin: 0 auto;
    border: 1px solid #D9D9D970;
}
.surveyBannerContainer {
    position: relative;
    padding: 10px 0 30px 0;
    background-color: #fff;
    max-width: 758px;
    margin: 0 auto;
    width: 100%;
    height: auto;
    aspect-ratio: auto 758 / 202;
}
.surveyBanner{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 758px;
    height: 100%;
}
.surveyBannerText{
    position: absolute;
    top: 42%;
    left: 47%;
    transform: translate(-50%, -50%);
    color: #fff;
}
.surveyBannerText > p{
    font-size: 3rem;
}
.surveyButton{
    background-color: #D9D9D963;
    border-radius: 25px;
    padding: 5px 8px;
    color: #FFF;
    font-size: 1.9rem;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.surveyButton:hover{
    background-color: #d9d9d98f;
    color: #fff;
}
.hide-survey {
    position: absolute;
    z-index: 3;
    top: 20%;
    left: 95.5%;
    font-size: 16px;
    transform: translate(-50%, -50%);
    color: #4D2B85;
    cursor: pointer;
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coursesView{
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
}
.sectionTitle{
    font-size: 2rem;
    text-align: center;
    color: #999999;
    padding-top: 40px;
    font-weight: 600;
    padding-bottom: 30px;
}
.coursesView ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0 30px 0;
}
.categoryList{
  margin: 0 20px;
  color: #F39422;
  border: 1px solid #F39422;
  /* background-color: #D9D9D963; */
  padding: 5px 8px;
  font-size: 1.9rem;
  border-radius: 25px;
  cursor: pointer;
  width: auto;
  display: inline-block;
}
.categoryList.active{
   background-color: #F39422;
   border-radius: 25px;
   color: #fff !important;
}

.coursesCards{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.courseCard{
    width: 20%;
    max-width: 250px;
    background-color: #fff;
    margin: 0 20px;
    border-radius: 21px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.courseCardAncor{
    text-decoration: none;
    outline: none;
    cursor: pointer;
}
.courseCard a> img{
    width: 100%;
    height: 135px;
    object-fit: cover;
    /* border-radius: 15px 15px 0 0; */
}
.courseCard a> div {
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 20px;
    height: 150px;
    flex-direction: column;
    justify-content: space-between;
}
.courseName{
    color: #4D2B85;
    font-size: 18px;
    font-weight: bold;
}
.courseDesc{
    color: #6A6A6A;
    /* font-size: 15px; */
    white-space: nowrap;
    font-size: 13px;
    /* white-space: nowrap; */
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;    
}
.courseMore{
    color: #F39422 !important;
    font-size: 16px;
}
.courseMore:hover{
    text-decoration: underline !important;
}
.viewAllCourses{
    color: #F39422 !important;
    font-size: 18px !important; 
    text-decoration: underline !important;
    text-align: right;
    width: 90%;
    margin: 0 auto;
    line-height: 80px;
}
.mawahebPropContainer{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 30px auto 30px auto;
    width: 85%;
}
.mawahebProp{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    justify-content: flex-end;
}
.mawahebProp > img{
    width: 100px;
    height: 100px;
}
.mawahebPropText {
    color: #6A6A6A;
    text-align: center;
    width: 65%;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 400;
    line-height: 22px;
}
.masterTalents{
    margin-bottom: 5rem;
}
.talentSteps {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 30px 0;
}
.talentStep{
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.talentStep div {
    border-radius: 100px;
    background-color: #F6F6F6;
    width: 150px;
    height: 150px;
}
.talentStep img{
    padding: 30px;
    width: 150px;
    height: 150px;
}
.talentStep p {
    text-align: center;
    color: #999999;
    font-weight: 500;
    font-size: 18px;
    margin-top: 8px;
}
/* new accordion */

  /* Core styles/functionality */
  .q-tab input {
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 1%;
    cursor: pointer;
  }
  .tab_q_content {
    /* max-height: 0;
    overflow: hidden;
    transition: all 0.35s; */
    display: none;

    max-height: 150vh;
    padding: 1.2rem 4.2rem;
    color: #6A6A6A;
    max-width: 950px;
    margin: 0 auto;
    font-size: 18px;
    border-radius: 15px;
    margin-bottom: 7px;    
  }
  /* .q-tab input:checked ~ .tab_q_content {
    max-height: 150vh;
    padding: 1.2rem 4.2rem;
    color: #6A6A6A;
    direction: ltr;
    max-width: 950px;
    margin: 0 auto;
    font-size: 18px;
    border-radius: 15px;
    margin-bottom: 7px;
}   */
  /* Visual styles */
  .questions-accordion {
    overflow: hidden;
    /* padding-top: 50px; */
    padding: 15px 0;
    background-color: #F5F5F5;

  }
  .tab_q_label,
  .tab__close {
    display: flex;
    align-items: center;
    color: #6A6A6A;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    background: #F5F5F5;
    cursor: pointer;
  }
  .tab_q_label {
    justify-content: space-between;
    padding: 1.2rem 4.2rem;
    font-size: 18px;
    /* margin: 0 !important; */
    border-top: 2px solid #fff;
    font-weight: normal;
    width: 80%;
    max-width: 950px;
    margin: 0 auto;
  }
  .q-tab:nth-child(1) .tab_q_label{
    border-top: none;
  }
  .tab_q_label::after {
    content: "\276F";
    width: 1em;
    text-align: center;
    transform: rotate(90deg);
    transition: all 0.35s;
    color: #F39422;
  }
  .q-tab input:checked + .tab_q_label::after {
    transform: rotate(270deg);
  }
  .tab_q_content p {
    margin: 0;
    padding: 1rem;
  }
  .tab__close {
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }
  .accordion--radio {
    color: #4d2b85;
  }
  
  .chatBot-icon > img{
    width: 22px;
    height: 21px;
  }
  /* Arrow animation */
  .q-tab input:not(:checked) + .tab_q_label:hover::after {
    animation: bounce 0.5s infinite;
  }
  .bitmovinplayer-container{
   background-color: #fff !important;
}
  @keyframes bounce {
    25% {
      transform: rotate(90deg) translate(0.25rem);
    }
    75% {
      transform: rotate(90deg) translate(-0.25rem);
    }
  }

@media(max-width:1290px){
    .intro-section {
        width: 90%;
    }
    .landing-video {
        max-width: 500px;
    }
}
@media(max-width:1060px){
    .landing-video {
        max-width: 470px;
    }
    .intro-landing-h1 {
        font-size: 25px !important;
    }
}
@media only screen and (max-width: 992px) {
 
    .intro-section {
        height: auto;
        display: flex;
        padding-bottom: 30px;
        width: 95%;
        flex-direction: column-reverse;
    }
    .intro-video {
        width: 85%;
        display: flex;
        margin: 0 auto;
        padding-top: 6%;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
   
    .action-text-intro > div {
        width: 90%;
        margin: 5px auto;
        text-align: justify;
        line-height: 25px;
    }
    .video-intro-section {
        display: block;
        width: 100%;
        padding: 0px;
        float: none;
        margin: 0 auto;
    }
    .video-intro-section > .note-video-clip {
        /* height: auto !important; */
        width: 100% !important;
    }
   
    details[open] {
        color: #969696;
        text-align: justify;
        line-height: 25px;
    }
    details summary {
        text-align: left;
    }
    details[open] > summary > #close , details #open  {
        text-align: left;
        font-weight: bold;
    }
    .landing-video {
        max-width: 100%;
        margin:0px auto 25px auto;
        border-radius: 20px;
    }
    .action-text-intro {
        display: block;
        width: 100%;
        float: none;
    }
    .intro-landing-h1 {
        font-size: 25px !important;
        padding: 0;
        text-align: center;
    }
    .courseCard a> img{
        border-radius: 15px 15px 0 0;
    }
    .intro-paragraph {
        width: 100%;
        font-size: 19px !important;
        margin: 0 auto;
        text-align: justify;
        padding: 0px;
        color:  #969696;
        line-height: 25px;
    }
    .action-text-intro > div > .call-to-action-btn{
        margin: 20px auto;
    }
    .action-text-intro {
        margin-top: 20px;
    }
    details {
        text-align: justify;
        font-size: 19px !important;
        color: #969696;
    }
    .intro-title {
        color: #969696;
        font-size: 25px;
    }
    .videos-carousel-container .slider-items .swiper-button-prev, .videos-carousel-container .slider-items .swiper-button-next{
        width: 50px !important;
    }
    /* Courses Slider Arrows */
    .prevCourse, .nextCourse
    {
        display: block !important;
        height: 100% !important;
        top: 0 !important;
    }
    .swiper-item {
        border-radius: 15px;
    }
    .item-title-slider {
        border-radius: 0 0 15px 15px;
    }
    .all-related-courses > .swiper .swiper-wrapper {
        justify-content: normal;
    }
  
    /* .certificate-container {
        background-image: url(/public//assets/images/mob_footer1.png);
        height: auto;
        margin-bottom: 0;
        background-repeat: no-repeat;
    } */
    .certificate-container:after {
        display: none;
      }
    .certificates-section {
        margin: 40px auto 130px auto;
        align-items: baseline;
        height: 130px !important;
    }
    
    .intro-title-certificate {
        color: #969696;
        font-size: 25px;
        width: 80%;
        padding-top: 20%;
        margin: 0 auto;
    }
    .single-certificate > img {
        width: 50%;
    }
    .single-certificate > img {
        max-width: 40px;
        height: 60%;
    }
    .single-certificate > p {
        font-size: 15px;
        line-height: 20px;
    }
    .all-related-courses > .slider-items > .owl-stage-outer > .owl-stage {
        justify-content: center;
        width: unset !important;
        margin: 0 auto;
        display: flex;
    }
    .all-related-courses > .slider-items > .owl-stage-outer {
        width: 88% !important;
        height: 100%;
        margin: 0 auto;
    }
    /* .all-related-courses{
        height: 11rem;
    } */
    .all-related-courses > .slider-items > .owl-stage-outer .owl-stage {
            /* height: 50%; */
            display: flex;
            align-items: flex-start;
    }
    
    .item-title-slider > span {
        font-size: 16px;
    }
    /* Testimonials */
   
    .customer-img > img {
        /* max-width: 130px; */
        margin: 0 auto;
        /* width: 90%; */
    }
    
    .customer-qoute {
        padding: 0 0 0 15px;
        margin: 0 auto;
    }
    .customer-img {
        display: flex;
    }
    /* new Landing page */
    .coursesCards{
        flex-wrap: wrap;
        justify-content: center;
        /* aspect-ratio: auto 3 / 3; */
    }
    .courseCard{
        width: 40%;
        max-width: 250px;
        margin: 10px;
    }
    .courseCard a> div {
        padding: 10px;
        height: 110px;
    }
    .courseCard a> img {
        height: 90px;
    }
    .viewAllCourses {
        width: 80%;
        line-height: 70px;
    }
    .mawahebProp{
        width: 30%;
    }
    .mawahebPropContainer{
        width: 100%;
        font-size: 16px;
    }
}
@media(max-width:768px){
   
    .intro-paragraph{
        width: 100%;
      }
      .intro-title {
        color: #969696;
        font-size: 25px;
        padding: 0 5px;
    }
    .intro-container {
        border-radius: 150px 0 150px 0;
    }
    .categories-container > .intro-title {
        font-size: 25px;
    }
    .all-related-courses {
        justify-content: space-evenly;
        margin: 20px auto;
    }
  
    .hide-survey {
        top: 23%;
        left: 94.5%;
    }
    .single-category {
        width: 100%;
        height: auto;
        max-height: unset;
        min-height: unset;
        padding-bottom: 0;
    }
    .single-category > img {
        width: 100%;
    }
    .each-cat {
        width: 100%;
    }
    .cat-name {
        display: none;
    }
  
    /* .cat-image-container:after{
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-width: 16px 10px 0;
        bottom: -15px;
        left: 18%;
        margin-left: -10px;
     } */

    /* .single-category:nth-child(1) .cat-image-container:after{
        border-color: #152a88 transparent;
             }
   .single-category:nth-child(2) .cat-image-container:after{
        border-color: #00ff4c transparent;
    }
   .single-category:nth-child(3) .cat-image-container:after{
        border-color: #eb8704 transparent;
    }
   .single-category:nth-child(4) .cat-image-container:after{
        border-color: #ff000d transparent;
    }
   .single-category:nth-child(5) .cat-image-container:after{
        border-color: #fce300 transparent;
    } */
  
      .single-certificate {
        width: 25%;
    }
    .single-certificate > img {
        width: 50%;
        height: 60%;
    }
    .certificates-section {
        height: auto;
    }
    /* .certificate-container{
        border-radius: 100% 100% 0 0;
    } */
  
 
   
    .each-cat > p , .each-course {
        font-size: 15px !important;
    }
    .all-related-courses > div > img {
        padding-bottom: 6px;
        width: 50px;
    }
    .thumbnail-img{
        height: 100%;
    }
    .slider-items {
        width: 100% !important;
        margin-left:10px !important;
    }
    .intro-title-certificate {
        font-size: 22px;
    }
    #circle-big {
        width: 110px;
        height: 110px;
    }
    #circle-small {
        width: 70px;
        height: 70px;
        visibility: hidden;
    }
    
    .owl-next, .owl-prev {
        width: 10%;
    }
    /* Testimonials */
    .testimonials > .swiper > .swiper-wrapper {
        padding: 10px 0px;
        background-color: #fff;
    }
    .testimonial-card {
        flex-direction: column;
    }
    .testimonials-title{
        line-height: unset;
        margin-bottom: 25px;
    }
    .customer-img > img {
        max-width: 130px;
        margin: 0 auto;
        width: 100%;
    }
   
    .customer-qoute {
        padding: 0px;
        width: 95%;
        margin: 0 auto;
    }
    .customer-img {
        width: 100%;
        display: flex;
    }
    .lp-intro-container {
        background-size: contain;
    }
    .lp-btn {
        border-radius: 40px;
        background: #F39422;
        width: 90%;
        height: 50px;
        margin: 0 auto;
        color: #fff;
        margin: 3rem auto 0 auto;
        font-size: 2.1rem;
        position: fixed;
        left: 5%;
        display: flex !important;
        text-align: center;        
        align-items: center;
        top: 84%;
        z-index: 4;
        justify-content: center;
    }
    .lp-intro-title {
        line-height: unset;
        margin-top: 0;
        font-size: 24px;
        padding: 0 5px;
    }
    .lp-intro-p{
        text-align: justify;
    }
    .courseName{
        font-size: 18px;
        line-height: 17px;
    }
    .courseDesc{
        font-size: 15px;
    }
    .courseMore{
        font-size: 15px;
    }
    .coursesView ul{
        overflow: auto;
        justify-content: flex-start;
        white-space: nowrap;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .coursesView ul::-webkit-scrollbar {
        display: none;
  }
.web-intro{
    display: none;
}
.mob-intro{
    display: block;
}
.mawahebProp {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid #DADADA6B;
    padding: 10px 0;
    width: 90%;
}
.mawahebProp:last-child{
    border-bottom: none;
}
.mawahebPropText {
    text-align: left;
}
.mawahebProp > img{
    width: 90px;
    height: 90px;
}
.sectionTitle {
    font-size: 2.5rem;
    font-weight: 700;
}
.mawahebPropContainer {
    margin: 10px 0 30px 0;
}
.masterTalents {
    margin-bottom: 2rem;
}
.talentStep{
    width: 33%;
    margin: 0;
}
.talentStep div {
    border-radius: 100px;
    background-color: #F6F6F6;
    width: 100px;
    height: 100px;
}
.talentStep img {
    padding: 15px;
    width: 100px;
    height: 100px;
}
.tab_q_label {
    padding: 1.2rem 1.2rem;
    width: 90%;
}
.surveyBanner{
    width: 100%;
}
.surveyBannerText > p {
    font-size: 20px;
}
.surveyButton {
    font-size: 1.4rem;
}
.surveyBannerText {
    top: 40%;
    left: 40%;
}
.coursesView ul{
    margin: 0;
    margin-bottom: 10px;
}
.newsletter-text {
    justify-content: center;
}
.whole-form > form > .reg-btn {
    max-width: 100px;
    padding: 4px 0;
    margin: 0 10px;
}
.categoryList{
 font-size: 2rem;
}
}
@media(max-width:650px){
    .green-bg{
        flex-direction: column;
    }
    .categories-container {
        background-color: #fff;
        border-radius: 0;
        padding-top: 20px;
    }
    .courses-section {
        /* padding-bottom: 30px; */
    }
    .courses-section {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        align-content: flex-start;
    }
    .cat-image{
        position: relative;
        width: 100%;
    }
    .each-course > p{
            margin: 0;
            line-height: 19px;
            font-size: 19px;
    }
    .each-course > img {
        width: 80px !important;
        margin: 0 auto;
        max-width: 120px;
        }
    .cat-image-container {
        position: relative;
    }
    .intro-container{
        background-image: none;
        padding: 30px 0 0px 0;
        background-color: transparent;
        border-radius: none;

    }
    .intro-video > img {
        width: 90%;
    }
    .intro-container {
        /* margin-top: 117px !important; */
        background-size: unset;
    }
    .inline-group{
        width: 100%;
    }
    .each-cat:nth-child(1) {
        content: url(../../../public/assets/images/mob_visualArts_cat.png);
    }

    .each-cat:nth-child(2){
        content: url(../../../public/assets/images/mob_steam_cat.png);
        
    }
    .each-cat:nth-child(3){
        content: url(../../../public/assets/images/mob_performingArts_cat.png);
        
    }
    .each-cat:nth-child(4){
        content: url(../../../public/assets/images/mob_sports_cat.png);
        
    }
    .each-cat:nth-child(5){
        content: url(../../../public/assets/images/mob_lifeSkills_cat.png);
        
    }
    .categories-container {
        padding-bottom: 0;
    }
    .certificate-container {
        background-image: url(/public/assets/images/mob_footer1.png);
        height: auto;
        margin-bottom: 0;
        background-repeat: no-repeat;
        /* margin-bottom: -130px; */
    }
    .amp-default-skin .vjs-controls-enabled .vjs-big-play-button {
        height: 50px !important;
        width: 50px !important;
    }
    .all-related-courses > .swiper > .swiper-button-next:after {
        display: block !important;
        color: #F39422;
        font-weight: bolder;
        font-size: 30px;
        left: 73%;
        top: 25%;
        position: absolute;
    }
    .all-related-courses > .swiper > .swiper-button-next{
        right: 0;
    }
    .all-related-courses > .swiper > .swiper-button-prev{
        left: 0;
    }
    .all-related-courses > .swiper > .swiper-button-next, .all-related-courses > .swiper > .swiper-button-prev{
        top: 10% !important;
    }
.all-related-courses > .swiper > .swiper-button-prev:after  {
    display: block !important;
    color: #F39422;
    font-weight: bolder;
    font-size: 30px;
    left: 0%;
    top: 20%;
    position: absolute;
}
}
@media(max-width:500px){
 
    .customer-img > img {
        max-width: 100%;
    }
    .qoute1 > div > img {
        width: 20px;
    }
    .qoute2 > div > img {
        width: 20px;
    }
    .hide-survey {
        top: 27%;
        left: 93.5%;
    }
}
@media(max-width:380px){
    .label-mawaheb {
        left:70px;
    }
 
    .courses-section {
        height: auto;
        display: flex;
        flex-direction: column;
    }
   
    .each-cat > p {
        font-size: 14px !important;
    }
    .amp-default-skin .vjs-controls-enabled .vjs-big-play-button {
        height: 40px !important;
        width: 40px !important;
    }
}