@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.custom-player{
    position : relative;
}
.video-container,
.video-controls,
.video-timer,
.video-options {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-container {
  width: 98%;
  user-select: none;
  overflow: hidden;
  /* max-width: 900px; */
  border-radius: 5px;
  /* background: #000; */
  aspect-ratio: 16 / 9;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.video-container.fullscreen {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  border-radius: 0px;
}

.video-overly{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
}

.custom-video-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  bottom: -15px;
  transition: all 0.08s ease;
}
.video-container.show-controls .custom-video-wrapper {
  opacity: 1;
  bottom: 0;
  transition: all 0.13s ease;
  z-index: 10;
}
.custom-video-wrapper::before {
  content: "";
  bottom: 0;
  left:0;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: calc(100% + 0px);
  pointer-events: none;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}
.video-timeline {
  height: 7px;
  width: 100%;
  cursor: pointer;
}
.video-timeline .progress-area {
  height: 3px;
  position: relative;
  background: rgba(255, 255, 255, 0.6);
}
.progress-area span {
  position: absolute;
  left: 50%;
  top: -25px;
  font-size: 13px;
  color: #fff;
  pointer-events: none;
  transform: translateX(-50%);
}
.progress-area .progress-bar {
  width: 0%;
  height: 100%;
  position: relative;
  background: #2289ff;
}
.progress-bar::before {
  content: "";
  right: 0;
  top: 50%;
  height: 13px;
  width: 13px;
  position: absolute;
  border-radius: 50%;
  background: #2289ff;
  transform: translateY(-50%);
}
.progress-bar::before,
.progress-area span {
  display: none;
}
.video-timeline:hover .progress-bar::before,
.video-timeline:hover .progress-area span {
  display: block;
}
.custom-video-wrapper .video-controls {
  padding: 5px 20px 10px;
}
.video-controls .video-options {
  width: 100%;
  margin-top: 0;
  padding-bottom: 0;  
}
.video-controls .video-options:first-child {
  justify-content: flex-start;
}
.video-controls .video-options:last-child {
  justify-content: flex-end;
}
.video-options button {
  height: 40px;
  width: 40px;
  font-size: 19px;
  border: none;
  cursor: pointer;
  background: none;
  color: #efefef;
  border-radius: 3px;
  transition: all 0.3s ease;
}
.video-options button :where(i, span) {
  height: 100%;
  width: 100%;
  line-height: 40px;
}
.video-options button:hover :where(i, span) {
  color: #fff;
}
.video-options button:active :where(i, span) {
  transform: scale(0.9);
}
.video-options button span {
  font-size: 23px;
}
.video-options input {
  height: 4px;
  margin-left: 3px;
  max-width: 75px;
  accent-color: #0078ff;
}
.video-options .video-timer {
  color: #efefef;
  margin-left: 15px;
  font-size: 14px;
}
.video-timer .separator {
  margin: 0 5px;
  font-size: 16px;
  font-family: "Open sans";
}
.playback-content {
  display: flex;
  position: relative;
}
.playback-content .speed-options {
  position: absolute;
  list-style: none;
  left: -40px;
  bottom: 40px;
  width: 95px;
  overflow: hidden;
  opacity: 0;
  border-radius: 4px;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: opacity 0.13s ease;
}
.playback-content .speed-options.show {
  opacity: 1;
  pointer-events: auto;
}
.speed-options li {
  cursor: pointer;
  color: #000;
  font-size: 14px;
  margin: 2px 0;
  padding: 5px 0 5px 15px;
  transition: all 0.1s ease;
}
.speed-options li:where(:first-child, :last-child) {
  margin: 0px;
}
.speed-options li:hover {
  background: #dfdfdf;
}
.speed-options li.active {
  color: #fff;
  background: #3e97fd;
}
.video-container video {
  width: 100%;
  border-radius: 5px;
}

@media screen and (max-width: 540px) {
  .custom-video-wrapper .video-controls {
    padding: 3px 10px 7px;
  }
  .video-options input,
  .progress-area span {
    display: none !important;
  }
  .video-options button {
    height: 30px;
    width: 30px;
    font-size: 17px;
  }
  .video-options .video-timer {
    margin-left: 5px;
  }
  .video-timer .separator {
    font-size: 14px;
    margin: 0 2px;
  }
  .video-options button :where(i, span) {
    line-height: 30px;
  }
  .video-options button span {
    font-size: 21px;
  }
  .video-options .video-timer,
  .progress-area span,
  .speed-options li {
    font-size: 12px;
  }
  .playback-content .speed-options {
    width: 75px;
    left: -30px;
    bottom: 30px;
  }
  .speed-options li {
    margin: 1px 0;
    padding: 3px 0 3px 10px;
  }
  .right .pic-in-pic {
    display: none;
  }
}

.transcript-items.highlighted{
    color :red;
}



@media screen and (max-width: 400px) {

  .custom-subtitle{
    bottom: 45px !important;
  }  
  .custom-subtitle span{
    font-size: 1.2rem !important;
  }

}

@media screen and (min-width: 400px) and (max-width: 600px) {

  .custom-subtitle{
    bottom: 60px !important;
  }  
  .custom-subtitle span{
    font-size: 1.4rem !important;
  }

}

@media screen and (min-width : 600px) and (max-width: 700px) {

  .custom-subtitle{
    bottom: 60px !important;
  }  
  .custom-subtitle span{
    font-size: 1.6rem !important;
  }

}
@media screen and (max-width: 450px) {
    .fullscreen .custom-subtitle{
      bottom: 38% !important;
    }

    .video-container.fullscreen video{
      object-fit: contain !important;
    }

}

.video-container.fullscreen{
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  background: rgba(51,51,51,1) !important;
  z-index: 1000 !important;
}

.video-container.fullscreen video{
  object-fit: cover;
}

.closeFullscreen i{
  width: 100%;
  height: 100%;
  font-size: 25px;
  color: white;
}
.closeFullscreen{
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 1001;
  display: none;
}

.video-container.fullscreen .closeFullscreen{
  display: block;
}

.video-cover-image{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  background: rgba(51,51,51,1) !important;
}

.video-container.fullscreen .video-cover-image{
  object-fit: contain !important; 
}