  .user-navbar {
    /*
    position: fixed;
    top: 20px;
    right: 30px;
    */
  }

  .user-navbar .profile {
    position: relative;
    /*
    width: 60px;
    height: 60px;
    */
    font-size: 22px;
    color: #F39422 ;
    overflow: hidden;
    cursor: pointer;
  }
  
  .user-navbar .profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .user-navbar .menu {
    position: absolute;
    /* top: 120px; */
    /* top: 0;
    right: -10px; */
    padding: 10px 20px;
    background: #fff;
    width: 0;
    top: -8.2rem;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    overflow-x: hidden;
    /* transition: all .5s linear; */
    /* transition: 0.5s; */
    transition-duration: 0.8s;
    padding-top: 60px;        
    z-index: 2;
    text-align: center;    
    border-radius: 51% 0% 0% 43% / 27% 22% 0% 27%;;
  
  }
  
  .user-navbar .menu.active {
    /* top: 80px; */
    visibility: visible;
    opacity: 1;
    background-color:rgba(0, 0, 0, 0.6)  ;   
    right: -8.2rem;
    top: -8.2rem;
  }
  
  /* .user-navbar .menu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 28px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
  }
   */
  .user-navbar .menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
    color: #555;
    line-height: 1.5em;
  }
  
  .user-navbar .menu h3 span {
    font-size: 14px;
    color: #cecece;
    font-weight: 300;
  }
  
  .user-navbar .menu ul li {
    list-style: none;
    padding: 16px 0;
    /* border-top: 1px solid rgba(0, 0, 0, 0.05); */
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #90a3ba;
  }
  
  .user-navbar .menu ul li img,  .user-navbar .menu ul li i {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
  }
  
  .user-navbar .menu ul li:hover img,  .user-navbar .menu ul li i {
    opacity: 1;
  }
  
  .user-navbar .menu ul li a {
    display: inline-block;
    text-decoration: none;
    color: rgb(201, 201, 201);
    font-weight: 500;
    transition: 0.5s;
  }
  
  .user-navbar .menu ul li:hover a {
    color: #ff5d94;
  }
  /* ..................side nav.................. */
  /* side nav */
#mySidenav {
  width: 250px;
  position: absolute;
  overflow-y: auto;
  top: 0;
  right: -250px;
  background-color:rgba(0, 0, 0, 0.6)  ;   
  overflow-x: hidden;
  /* transition: all .5s linear; */
  /* transition: 0.5s; */
  transition-duration: 0.8s;
  transition: 0.5s;
  padding-top: 60px;        
  z-index: 2;
  text-align: center;    
  border-radius: 51% 0% 0% 43% / 27% 22% 0% 27%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0; */
}

.burger-nav{
  cursor: pointer;
}

.scrollbar-hidden::-webkit-scrollbar {
  display:block;
}
.logout > hr{
  display: none;
}
.sidenav a {
  text-decoration: none;
  font-weight: bold;
  color: #90a3ba;
  display: block;
  height: 75px;
  transition: 0.3s;
  width: 200px;
}
.sidenav a:hover {
  color:#f49422;
}
.menu-link {
  /* color: #6d6b6c !important; */
  font-size: 25px;
}
.web-login{
  border-bottom: 1px solid rgb(243, 148, 34);
  border-radius: unset;
  padding: 0px;
  font-size: 21px;
  color:rgb(243, 148, 34) !important;
  /* font-weight: 700; */
}
.web-register{
  background-color: rgb(243, 148, 34);
  color: #fff !important;
  font-size: 21px;
  padding: 5px 15px;
  border-radius: 25px;
}
.menu-item.prices, .menu-item.courses, .menu-item.kids_safe, .menu-item.dashboard{
  right: 70px;
}
.kids_safe > a > img{
  /* width: 110px;
  height: 40px; */
    max-width: 100%;
    height: auto !important;
    aspect-ratio: auto 11/4;
}

.sidenav .closebtn {
  /* position: absolute; */
  top: 0;
  font-size: 50px;
  color: #acacac;
  font-weight: 100;
  margin-left: 0.5em;
  display: flex;
  justify-content: flex-end;
  margin-top: -1.2em;
  text-decoration: none;
}
.side-item-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side-item , .dropdown-item{
  display: block;
  text-decoration: none;
  width: 90%;
  margin: 0 auto;
}
.user-loggedin {
  width: auto !important;
  text-align: right;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.user-loggedin > p{
  font-size: 25px;
  margin: 0;
  color: #2fcea6;
  text-transform: capitalize;

}
.hello-word{
  margin-right:10px !important;
}
.side-icon > img {
  display: inline-block;
  /* max-width: 40px; */
  height: 16px;
  width: 100%;
}
.side-icon{
  display: inline-block;
  width: 30%;
}
.menu-l{
    font-size: 17px !important;
    width: 100%;
    margin: 0 auto;
}
.submenu-i{
  margin: 0 !important;
  padding: 0 !important;
}
.side-item > p , .dropdown-item > p{
  display: inline-block;
  font-size: 22px;
  color: #fff;
  font-weight: normal;
  /* line-height: px; */
  width: 70%;
  vertical-align: middle;
  margin: 0;
  line-height: 25px;
}
.horizontal-separator{
  width: 90%;
  color: rgb(216, 216, 216);
  margin: 10px auto 10px auto
}
.active-side-item{
  color:#F39422 !important;
  background-color: white;
  border-radius: 20px;
  padding: 5px;
}
.dropdown-sidenav {
  display: none !important;
}
.prices-link{
  display: none;
}
/* ---Sidebar css */
.text-primary{
  color: #5a8dee!important;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
#navbar #menu .loggedUserSearch{
  margin-right: 8rem !important;
}
.hello-name{
  visibility: hidden;
}
.sidebar{
    width: 300px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    position: fixed;
    top: 0;
    right: -300%;
    z-index: 3;
    transition: 0.5s;
    /* background-color:rgba(0, 0, 0, 0.6)  ;    */
    background-color: #FFFFFFF2;
    /* border-radius: 51% 0% 0% 43% / 27% 22% 0% 27%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.dropdownMenu{
  width: auto;
  min-height: auto;
  bottom: 100%;
  z-index: 1;
  position: absolute;
  transition: 0.5s;
  animation: open .2s linear;
  transform: translateY(-10%);
  opacity: 0;
  visibility: hidden;
}
.dropdownMenu.active{
  position: unset;
  transition: 0.5s;
  animation: open .2s linear;
  transform: translateY(0%);
  opacity: 1;
  visibility: visible;
}
.sidebar.active{
    right: 0;
}
.hide-from-web{
  display: none !important;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0,0,0,0.4); */
    background-color: #303030C2;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
    z-index: 2;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 20px);
    overflow-x: hidden;
    padding: 0 15px 25px 15px;
}
.sd-body ul{
    display: block;
    width: 66%;
    margin: 0 auto;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
    margin-top: 10px;
}
.sd-body ul li:last-child .sd-link{
  border-bottom: none;
}
.sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 10px;
    color: #475f7b;
    background-color: transparent;
    border-radius: 0px;
    border-bottom: 1px solid #E8E8E8;
    cursor: pointer;
    text-decoration: none;
}
.sd-header > .btn {
   color: #555;
   font-size: 25px;
}
.hide-overflow{
  overflow: hidden;
}
/* body:has(#root > .header > .wrapper > .div > .sidebar.active) {
  overflow: hidden;
  background-color: #ff5d94;
} */

/* .submenu-item-course:last-child .menu-link{
  color: #f49422 !important
} */
.arrowImg , .arrowImgLang{
  transition: all 0.4s ease;
}
.sub-btn{
  margin: 0;
}
.user-search {
  display: none !important;
}
#se_search-btn{
  background-size: 16px !important;
}
#se_search-box , #se_search-box::placeholder, .suggestionContainerMob, .suggestionContainerMob > .allSearch{
  color: #999999 !important;
}
.suggestionContainerMob > .allSearch{
  background-color: #f7f7f7 !important;
  box-shadow: none;
}
.suggestionContainerMob > .allSearch .autoCompleteResults a{
 color: #999999 !important;
}
/* -------------- Media Query ----------------- */
@media(max-width:992px){
  .hello-name{
    visibility: visible;
    display: flex;
    justify-content: space-between;
    color: #2fcea6 !important;
    text-transform: capitalize;  
    font-size: 23px;
}
.hello-name > p {
  margin-bottom: 0;
}
.side-item > p , .dropdown-item > p{ 
  width:80%; 
}
.side-item{
  width: 80%;
}
.hide-from-web{
  display: block !important;
}
  .sidebar{
    background-color:#FFFFFFF2;
    border-radius: 0;
    width: 350px;
    flex-direction: column;
    justify-content: flex-start;
  
  }
  .sd-body{
    max-height: calc(100vh - 100px);
}
  .sd-header{
    padding:15px 10px;
}
  .sd-header > .btn{
   padding: 6px 0px;
  }
/* .submenu-item > .menu-link {
    justify-content: center !important;
} */
.user-search {
  display: block !important;
}
  #mySidenav {
    background-color: #3a3a3a;
    padding-top: 40px;
    right: -400px;
}
  .sidenav a {
    height: 65px;
    width: 90%;
  }
  .side-item > p , .dropdown-item > p{
    text-align: left;
    padding: 0 0 0 10px;
    font-size: 18px !important;
  }
  .side-icon {
    width: 20% !important;
}
.sidenav-icon{
    display: inline-block;
    width: 40px;
}
.prices-link{
  display: block;
}
.current-lang {
  color: #f49422 !important;
  background-image: none;
  background-color: transparent;
}
.hide-from-mob{
  display: none;
}
/* -----------------DROPDOWN---------------------- */
 .item{
  position: relative;
  cursor: pointer;
 }
 .dropdown-sidenav{
  display: block !important;
  width: 80% !important;
 }
 .sub-btn{
  color: #999999;
  /* font-size: 16px; */
  text-decoration: none;
  display: block;
  padding: 0 0 0 10px;
  /* padding: 5px 30px;
  line-height: 60px; */
 }
 
 .sub-btn:hover{
  /* background: #33363a; */
  transition: 0.3s ease;
 }
 
 .sub-btn{
  display: inline-block;
  width: 70%;
    text-align: left;
    font-size: 18px;
 }
 
  .dropdown{
    position: unset !important;
    right: 0;
    padding: 0 !important;
    margin: 0 !important;
    display: inline-block;
    margin: 20px;
    width: 100%;
    transition: 0.3s ease; 
  }
  .dropdown > img {
    width: 40px;
    margin-right: 13px;
  }
 
.sub-menu{
  background: #262627;
  display: none;
 }
 
  .sub-menu a{
  padding-left: 80px;
 }
 
 .rotate{
  transform: rotate(270deg);
  transition: all 0.4s ease;
 }
 .kids_safe > a > img{
    width: 85%;
  }
  
}
@media(max-width:400px){
  #mySidenav{
    /* width: 100%; */
    right: -100%;
  }
  .sidebar{
    /* width: 100% !important; */
    width: 80% !important;
  }
}
