.blogs-section {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-around;  
    flex-direction: row;  */
    row-gap: 40px;     
    background-color: rgb(233, 233, 233);
    padding:50px;

    display: grid;
    /* grid-template-columns: auto auto auto;    */
    grid-template-columns: repeat( 3, minmax(250px, 1fr) ); 
}

.blog-item{
    width: 90%;
    padding: 30px;
    background-color: white;
    border-radius: 10px;   
    position: relative; 
    margin: 0 auto;    
}

.blog-image{
    width: 100%;
    border-radius: 5px;
}

.blog-title{
    font-weight: bold;
    font-size : 2rem;
    margin-top: 30px;
}

.blog-more{
    font-size: 2rem;
    margin-top: 30px;
    color: orange;    
}
.blog-more a{
    font-size: 2rem;
    color: orange;    
}

.blog-extra{
    position: absolute;
    bottom: 5px;
    right: 15px;
    color: orange;
    font-size: 3.5rem;
}

.blog-video-section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 9999;
    /* opacity: 0.8; */
    background: rgba(0,0,0,0.8);
}

.video-wrapper{
    width: 50%;
    height: 500px;
    margin: 0 auto;
    margin-top: 90px;
    color: white;
}

.video-wrapper .v-title{
    font-size: 2rem;
    margin-top: 30px;
}

.close-video-blog{
    color: #686666;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;    
}

.blog-section{
    background-color: white;
}

.blog-container{
    width:70%;
    margin: 50px auto;
    position: relative;
}

.back-arrow{
    position: absolute;
    top : 0;
    left : -100px;
}

.lp-intro-p.mob-intro{
    margin-bottom: 20px;
}

.blog-inner-body p, .blog-inner-body div, .blog-inner-body span, .blog-inner-body img {
    max-width: 100%;
}  

.rtl{
    direction: rtl;
}

@media (max-width: 1000px) {
    .blogs-section {
        grid-template-columns: repeat( 2, minmax(250px, 1fr) ); 
        /* grid-template-columns: auto auto;  */
        padding-left: 10px;
        padding-right: 10px;        
    }

    .video-wrapper{
        width: 70%;
        height: 400px;
    }

}

@media (max-width: 750px) {
    .blogs-section {
        grid-template-columns: repeat( 1, minmax(250px, 1fr) ); 
        /* grid-template-columns: auto;  */
        padding-left: 5px;
        padding-right: 5px;
    }

    .blog-item{
        display: flex;    
        align-items: flex-start;    
    }

    .blog-image{
        width: 40%;
    }

    .blog-title-wrapper{
        padding: 0 20px;
    }

    .video-wrapper{
        width: 90%;
        height: 300px;
    }

    .blog-more, .blog-title {
         margin-top: 0px !important; 
    }    
    .blog-title, .blog-more a {
        font-size: 1.4rem !important;
    }
    .svg-inline--fa {
        height: 0.5em !important;
    }
}


@media (max-width: 700px) {

    .blog-container{
        width:100%;
        padding: 0 20px;
    }

    .back-arrow{
        left: 20px;
        top: -50px;
    }    

}