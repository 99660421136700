.footer {
    background-color: #5C5B5B;
    color: rgb(202, 202, 202);
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    width: 100%;
    height: 17rem;
    /* position: absolute; */
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer-blocks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.social-media-icons {
    width: 30% !important;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2.8rem;
}
.social-media-icons div {
    display: flex;
}
.social-media-icons div > a > img {
    padding: 10px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
}

.social-media-icons > a > img:hover{
    transform: rotate(350deg);
}

.footer-navigations{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 70%;
    margin: 0 auto;
    justify-content: space-evenly;
    margin-top: -1rem;
}
.footer-payment , .footer-contact, .footer-about{
    display: none;
}
.footer-anchor {
    cursor: pointer;
    color: rgb(202, 202, 202);
    font-size: 18px;    
    text-decoration: none !important;
}
.footer-anchor:hover{
    color:#F39422 !important;
}
.join-us:hover{
    color: rgb(202, 202, 202) !important;
}
.footer-anchor:active{
    color:#F39422 !important;
}

.footer-anchor:visited {
    color:rgb(202, 202, 202) 
}
.footer-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.block-2{
    align-items: center;
    text-align: center;
}
.block-1 , .block-3{
    align-items: right;

}
.block-1  {
    border-right: 1px solid rgb(202, 202, 202);;
    padding-right: 80px;
    height: 55px;
}
.block-3 {
    border-left: 1px solid rgb(202, 202, 202);;
    padding-left: 80px;
    height: 55px;
}
.our-sponsors{
    color: rgb(46, 46, 46);
    font-size: 18px;
}
.block-4 {
    display: flex;
    justify-content: space-around;
    margin-top: -2rem;
}
.block-4 > a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.block-4 > a > p{
    margin-top: 21px;
    padding: 0 9px;
}
.kids_safe_footer {
    /* width: 30%; */
    width: 400px;
    height: 50px;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.kids_safe_footer a {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.kids_safe_footer a img{
    width: 130px;
    height: 50px;
}
.infinite-scroller{
    display: none;
}

/*  New footer design */

.footer-section-1 { grid-area: box1; }
.footer-section-2 { grid-area: box2; }
.footer-section-3 { grid-area: box3; }
.footer-section-4 { grid-area: box4;
                    color: #99999999;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;}
.footer-section-5 { grid-area: box5; }
.footer-section-6 { grid-area: box6;
                    width: 80%;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly; }

.footer-sections{
    width: 100%;
    padding: 30px 100px;
    margin: 0 auto;
    display: grid;
    grid: 'box1 box1 box2 box3 box3 box4'
          'box1 box1 box2 box3 box3 box4'
          'box1 box1 box2 box3 box3 box4'
          'box1 box1 box2 box3 box3 box4'
          'box1 box1 box2 box6 box6 box4'
          'box1 box1 box5 box6 box6 box4';

    /* grid-gap: 20px; */
    grid-auto-flow: column;
    grid-column-gap: 10px;
    /* grid-auto-columns: 1fr;   */
    font-size: 16px;
    color: #D9D9D9;
}
.footer-section-1 > ul{
    margin-bottom: 0;
}
.footer-section-1 > ul > li{
    width: 70%;
    margin: 0 auto;
}
.footer-section-1 > ul > li,
.footer-section-2 > ul > li {
    margin: 7px 0;
}
.footer-section-1 > ul > li a,
.footer-section-2 > ul > li a{
    color: #D9D9D9;
}
.footer-section-4 > a{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.footer-section-4 > a:hover{
    color: #D9D9D9;
}
.footer-section-4 > a > p{
    font-size: 14px;
}
.footer_2{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4D2B85;
    height: 154px;
}
.text-side, .btn-side{
    margin: 0 20px;
}
.text-side p:first-child{
    font-size: 18px;
    color: #F7F7F7;
    font-weight: 900;
}
.text-side p:first-child > span{
    color: #F39422;
}
.text-side p:nth-child(2){
    font-size: 14px;
    font-weight: 400;
    color: #F7F7F7;
}
.footer2-btn {
    border-radius: 40px;
    background: #F39422;
    width: 200px;
    height: 40px;
    margin: 0 auto;
    display: block;
    color: #fff;
    margin-top: 0rem;
    font-size: 16px;
}
@media(max-width:1245px){
    .block-3 {
        padding-left: 5%;
    }
    .block-1 {
        padding-right: 5%;
    }
}
@media(max-width:1085px){
    .footer-sections{
        padding: 30px 70px;
    }
    .footer-section-1 > ul > li {
        width: 100%;
    }
}

@media(max-width:1000px){
    .footer{
        height: 150px;
    }
    .footer-navigations {
        display: flex;
        flex-direction: row;
        /* flex-basis: auto;
        padding-right: 20px; */
    }
    .footer-navigations > a {
        line-height: 23px;
    }
    .social-media-icons {
        padding-left: 20px;
    }
}
    
@media only screen and (max-width: 992px) {
    /* new footer */
    .footer-sections{
        padding: 30px 20px;
        grid: 'box1 box1 box1 box4 box4 box4'
              'box1 box1 box1 box4 box4 box4'
              'box2 box2 box2 box4 box4 box4'
              'box3 box3 box3 box3 box3 box3'
              'box6 box6 box6 box6 box6 box6'
              'box5 box5 box5 box5 box5 box5';
    }
    .footer-section-6{
        width: 80%;
        margin: 0 auto;
        padding: 10px 0 20px 0;
    }
    .footer-section-1 > ul > li, .footer-section-2 > ul > li {
        margin: 3px 0;
    }
    .footer-section-4 > a img{
        width: 80px;
    }
    .footer-section-5{
        text-align: center;
    }
    /* old footer */
    .footer{
        position: unset;
        /* background-color: #3a3a3a; */
        height: 190px;
    }
  .footer-contact, .footer-about {
        display: block;
    }
    .footer-copyright{
        display: none;
    }
    .social-media-icons {
        /* Uncomment the below When the sponsors will be displayed */
        /* flex-direction: column;
        padding-left: 0;
        width: 50%;
        display: flex;
        margin-top : 0;
        align-content: center; */

        /* Comment the below when the sponsors will be displayed  */
        flex-direction: row;
        padding-left: 0;
        width: 50% !important;
        display: flex;
        align-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
    }
    .infinite-scroller {
        width: 25%;
    }
    .footer-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px;
        align-items: flex-start;
    }
    .footer-blocks{
        flex-wrap: wrap;
    }
    .block-1 {
        padding-right: 0%;
        border-right: none;
    }
    
    .block-3 {
        border-left:none;
        padding-left: 0%;
    }
    .block-4{
        margin-top: 0;
    }
    .footer-navigations {
        display: flex;
        flex-direction: row;
        flex-basis: 60%;
        width: 50%;
        /* padding-right: 20px; */
        align-content: space-between;
        align-items: stretch;
        justify-content: space-between;
    }
    .infinite-scroller {
        width:50% !important;
        margin: 0;
        overflow: hidden;
        /* display: flex; */
        display: none;
        flex-direction: column;
        height: 80px;
        justify-content: flex-start;
        
    }
    .footer-anchor {
        font-size: 14px;
        text-align: center;
    }
    .footer-block {
        height: auto;
    }
    .footer-navigations {
        /* display: none !important; */
    /* if you want to add the navigations to the footer uncomment the below commented styles */

        /* display: none !important; */
        display: flex;
        flex-direction: row;
        flex-basis: auto;
        /* padding-left: 20px; */
        align-content: space-between;
        align-items: stretch;
        justify-content: space-between;
        margin-top: 0;
    }
    .our-sponsors {
        color: gray;
        font-size: 14px;
    }
 
}
@media(max-width:760px){
    .footer {
        height: 260px;
        display: flex;
        flex-direction: column;
        /* justify-content: flex-start; */
        justify-content: center;
    }
    .social-media-icons {
        flex-direction: column;
        width: 10% !important;
    }
    .footer-navigations{
        width: 60%;
    }
    .kids_safe_footer {
        width: 100%;
    }
    .footer_2 {
        flex-direction: column;
    }
}
@media(max-width:715px){
    .social-media-icons {
        flex-direction: column;
        align-content: center;
        width: 50% !important;
    }
    .footer-navigations {
        width: 50%;
        align-content: center;
        flex-direction: column;
    }
    .footer-block {
        align-items: center;
    }
}