.chatBot-icon{
    display: flex;
}
.chatBot-icon span{
    color: #F39422;
    font-size: 17px;
    margin: 0px 5px;
    font-weight: 700;
    line-height: 21px;
    margin-top: -2px;
}

@media(max-width:992px){
    .kidIpT{
        bottom: 92px !important;
    }
}